import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import BgImage from "../../images/food-pantry.jpg"
import Layout from "../../components/layoutPhoenixCustom"
import PageHeaders from "../../components/pageHeaders"
import SEO from "../../components/seo"

const ParagraphText = styled.p`
  font-size: 1.25rem;
`

const HeaderText = styled.h1`
  margin-top: 2rem;
  font-size: 1.5rem;
`

const IndexPage = () => (
  <Layout
    ImgSrc={BgImage}
    MainText="Food Pantry"
    SecondText="Showing our community that we care."
  >
    <SEO title="Food Pantry" />

    <PageHeaders
      css={css`
        display: flex;
      `}
    >
      Cross Church Food Pantry
    </PageHeaders>
    <HeaderText></HeaderText>
    <ParagraphText>
      We have Food Pantries on our campus that serve our community throughout
      the week. If you need Food, please stop by our pantry and grab a box for
      the week. If you would like to give back, we are always taking donations
      and in need of helpers.
    </ParagraphText>
    <PageHeaders
      css={css`
        display: flex;
        margin-top: 4rem;
      `}
    >
      More Info
    </PageHeaders>
    <HeaderText>Where?</HeaderText>
    <ParagraphText>
      Cross Church Phoenix.
      <br />
      1827 W. Grovers Ave.
      <br />
      Phoenix, AZ 85023
      <br />
    </ParagraphText>
    <HeaderText>When?</HeaderText>
    <ParagraphText>
      Sundays after service. Tuesdays / Thursday: 9am-11:30am
    </ParagraphText>
  </Layout>
)

export default IndexPage
